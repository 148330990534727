import { Component, OnDestroy } from "@angular/core";

import { environment } from "environments/environment";
import { Subscription } from "rxjs";

import { fadeInOutAnimation, ConfigurationState, ObservableService, PubnubService, DsLoggingService } from "@cubigo/digital-signage";

import { AuthService } from "app/shared/services";
import { ActivityDataService, BrandingDataService, ConfigDataService, DiningDataService, FooterDataService, NewsDataService } from "app/shared/services/data";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [fadeInOutAnimation]
})
export class HomeComponent implements OnDestroy {
  public setupDataComplete = false;

  private getConfigRetryCount = 0;
  private getConfigRetryDurations: number[] =
    [
      60000, // 1m
      300000, // 5m
      1800000, // 30m
      3600000, // 1h
      43200000, // 12h
      86400000, // 24h
      172800000 // 48h
    ];

  private getConfigSubscription: Subscription;

  public constructor(
    private pubNubService: PubnubService,
    private configDataService: ConfigDataService,
    private observableService: ObservableService,
    private activityDataService: ActivityDataService,
    private diningDataService: DiningDataService,
    private footerDataService: FooterDataService,
    private newsDataService: NewsDataService,
    private brandingDataService: BrandingDataService,
    private logginService: DsLoggingService,
    private authService: AuthService
  ) {
    this.logginService.environment = environment;
  }

  public ngOnDestroy(): void {
    this.pubNubService.unsubscribeAll();
    this.configDataService.configuration.next(null);
  }

  public setObservablesEvent(): void {
    this.getConfigSubscription?.unsubscribe();
    this.getConfigSubscription = this.configDataService.getConfig()
      .subscribe({
        next: (configuration: ConfigurationState) => {
          if (!configuration) {
            return;
          }

          this.configDataService.configuration.next(configuration);

          if (configuration.channel?.providerUuid && configuration.channel?.id && configuration.owner?.uuid) {
            this.pubNubService.init(
              environment.pubnub.pubKey,
              environment.pubnub.subKey,
              configuration.channel.providerUuid,
              configuration.channel.id,
              configuration.owner.uuid
            );
          }
          this.configDataService.configuration.next(configuration);
          this.observableService.getConfig = this.configDataService.getConfig();
          this.observableService.getActivities = this.activityDataService.getActivities(configuration.channel?.providerUuid, configuration.channel?.id);
          this.observableService.getDining = this.diningDataService.getDining(configuration.channel?.providerUuid, configuration.channel?.id);
          this.observableService.getDiningLocations = this.diningDataService.getDiningLocations(configuration.channel?.providerUuid);
          this.observableService.getWeather = this.footerDataService.getWeather();
          this.observableService.getMailArrived = this.footerDataService.getMailArrived();
          this.observableService.getNewsItems = this.newsDataService.getNewsItems(configuration.channel?.providerUuid, configuration.channel?.id);
          this.observableService.getBirthdays = this.newsDataService.getBirthdays(configuration.channel?.providerUuid);
          this.observableService.getPrioNews = this.newsDataService.getPrioNews(configuration.channel?.providerUuid, configuration.channel?.id);
          this.observableService.getBranding = this.brandingDataService.getBranding();
          this.observableService.getYoutubeLive = this.configDataService.getYoutubeLive(configuration.channel?.id);
          this.observableService.getScheduledActivities = this.activityDataService.getScheduledActivities(configuration.channel?.providerUuid, configuration.channel?.id);
          this.observableService.getServiceNames = this.configDataService.getServiceNames();
          this.observableService.initializeObservables();
        },
        error: () => {
          this.retrySetObservablesEvent();
        }
      });
  }

  private retrySetObservablesEvent(): void {
    // During deploys it is possble that the DS refreshes whilst the API is still warming up / is offline
    // To prevent this we added this retry logic that makes sure the DS tries to recover on set intervals (max duration decided by prd team)

    if (this.getConfigRetryCount >= this.getConfigRetryDurations.length) {
      // Maximum retry amount reached
      this.logginService.trackEvent(`Config retrieval failed ${this.getConfigRetryCount} times, logging out`);
      this.authService.logoutAndNavigate();
      return;
    }

    // Get the timeout duration for the next token refresh
    const timeoutDuration = this.getConfigRetryDurations[this.getConfigRetryCount];

    setTimeout(() => {
      // Trigger the next refresh after x time
      this.setObservablesEvent();
    }, timeoutDuration);

    this.getConfigRetryCount++;
  }
}
