import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { DigitalSignageModule } from '@cubigo/digital-signage';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { environment } from 'environments/environment';

import { authConfig } from 'app/shared/configs/auth.config';
import { httpInterceptorProviders } from 'app/shared/interceptors';

import { AppComponent } from 'app/app.component';
import { GlobalErrorHandler } from './shared/error-handler';
import { AppRoutingModule } from './app-routing.module';
import {
  LoginComponent,
  HomeComponent,
  LogoutComponent,
} from './components';
import { ConfigDataService } from './shared/services/data';
import { MultiTranslatePoHttpLoader } from './shared/loaders/multi-translate-po-http.loader';

export const createTranslateLoader = (http: HttpClient, configDataService: ConfigDataService) => new MultiTranslatePoHttpLoader(http, configDataService, [
  { prefix: '/assets/i18n/login', suffix: '.po' },
  { prefix: '/assets/i18n/digital-signage', suffix: '.po' }
]);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LogoutComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          environment.azureApiManagementUrl,
        ],
        sendAccessToken: true
      },
    }),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en-US',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient, ConfigDataService]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !environment.name.includes('local'),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AppRoutingModule,
    DigitalSignageModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    { provide: AuthConfig, useValue: authConfig },
    { provide: OAuthStorage, useValue: localStorage },
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
